import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import { useLocation } from '@reach/router';
import QueryString from 'query-string';
import { Helmet } from 'react-helmet';
import CarDetail from '@/common/core/model/carDetail';
import CarDigest from '@/common/core/model/carDigest';
import { searchCarStore } from '@/newcar/core/store/searchCarStore';
import { simulationManagerStore } from '@/newcar/core/store/simulationManagerStore';
import Layout from '@/common/ui/template/Layout';
import NEWCAR_DEFINE from '@/newcar/util/define';

interface DetailPageContext {
  car: CarDetail;
  digest: CarDigest;
}

// LP、マガジン、コラムなどから流入するリダイレクトページ
// 車両情報などをセッションストレージに格納し、申込みフォームへ遷移する
const Template: React.FC<PageProps<{}, DetailPageContext>> = ({ pageContext }) => {
  const {
    car,
    digest,
  } = pageContext;

  const location = useLocation();
  const query = QueryString.parse(location.search);

  useEffect(() => {
    // クエリを取得する
    if (window && query) {
      // 単一の車種情報をセット
      searchCarStore.setSingleCarInfo(digest, car);
      // クエリからentryBreakdownを作成し、保存
      simulationManagerStore.createCampaignBreakdownFromQuery(query);

      // 申請フォームへ遷移
      window.location.href = `${NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO}${location.search}`;
    }
  }, [query]);

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </Layout>
  );
};

export default Template;
